import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Container } from 'reactstrap';
import Footer from './Footer';
import { GlobalModalBackdrop } from './Modal';
import NavMenu from './NavMenu';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <GlobalModalBackdrop />
        <Switch>
            <Route path="/" exact>
                <NavMenu />
                <Container className="full-width">
                    {props.children}
                </Container>
            </Route>
            <Route>
                <NavMenu showBackground />
                <Container>
                    {props.children}
                </Container>
            </Route>
        </Switch>
        <Footer />
    </React.Fragment>
);
