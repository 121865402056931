import * as React from 'react';
import { ReactComponent as Logo } from '../img/icons/authority-logo.svg';
import './Footer.scss';

export default class Footer extends React.PureComponent {
    public render() {
        return (
            <footer className="footer">
                <div className="row flex-column justify-content-center">
                    <div className="col-auto">
                        <Logo className="logo" />
                    </div>
                    <div className="col-auto">
                        <img className="dynamics" src={require("../img/dynamics.png")} alt="Powered by Dynamics Inc." />
                    </div>
                    <div className="col-auto">
                        <small className="copyright">
                            Copyright &copy; {new Date().getFullYear()} The Authority. All rights reserved.
                        </small>
                    </div>
                </div>
            </footer>
        );
    }
}