import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { Get, HandleApiError } from "./APIRequest";
import Failable from "./Failable";

export interface AuthorityAsset {
    serialNumber: string;
    imageId: string;
    imageUrl: string;
    frontImage: string;
    backImage: string;
    title: string;
    collectibleType: string;
    additionalTags: { [key: string]: string };
    productHistory?: ProductHistoryEvent[];
}

export interface ProductHistoryEvent {
    date: string;
    description: string;
}

export interface AuthorityAssetState {
    loading: boolean;
    assetCache: { [key: string]: AuthorityAsset | null }
}

interface CacheAuthorityAssetAction { type: "CACHE_ASSET", serialNumber: string, asset: AuthorityAsset | null }
interface AuthorityAssetLoadingAction { type: "ASSETS_LOADING" }
interface AuthorityAssetCancelLoadingAction { type: "ASSETS__CANCEL_LOADING" }

type KnownAction = CacheAuthorityAssetAction | AuthorityAssetLoadingAction | AuthorityAssetCancelLoadingAction;

export const actionCreators = {
    loadAssetBySerialNumber: (serialNumber: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "ASSETS_LOADING" });
        return Get<Failable<AuthorityAsset>>(`AuthorityAsset`, { serialNumber })
            .then(asset => dispatch({ type: "CACHE_ASSET", serialNumber, asset: asset.success || null }))
            .catch(HandleApiError(dispatch));
    }
}

const defaultState: AuthorityAssetState = { loading: false, assetCache: {} };

export const reducer: Reducer<AuthorityAssetState> = (state: AuthorityAssetState | undefined, incomingAction: Action): AuthorityAssetState => {
    state = state || defaultState;

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ASSETS_LOADING':
            return { ...state, loading: true };
        case 'ASSETS__CANCEL_LOADING':
            return { ...state, loading: false };
        case 'CACHE_ASSET':
            return { loading: false, assetCache: { ...state.assetCache, [action.serialNumber]: action.asset } };
        default:
            const You_Missed_An_Action: never = action;
    }
    return state;
}