import { QueryStringBuilder } from "./UrlHelper";

export function Get<T>(url: string, data?: { [key: string]: string | number | boolean | undefined }, cached = false) {
    const destination = url + QueryStringBuilder(data);
    return Request<T>(destination, "GET", undefined, undefined, cached);
}

export function Post<T>(url: string, data?: { [key: string]: string | number | boolean | undefined }, cached = false) {
    if (typeof FormData === "undefined")
        return Request<T>(url, "POST"); //server side kickout
    const body = new FormData();
    Object.keys(data || {}).filter(k => typeof (data || {})[k] !== "undefined").forEach(k => body.append(k, ((data || {})[k] || "").toString()));
    return Request<T>(url, "POST", (body as any)._blob ? (body as any)._blob() : body, undefined, cached);
}

export function PostJson<T, U>(url: string, data: U, cached = false) {
    return Request<T>(url, "POST", JSON.stringify(data), "application/json", cached);
}

function Request<T>(url: string, method: string, data?: FormData | string, bodyType?: string, cached = false) {
    return new Promise<T>((resolve, reject) => {
        if (typeof window === "undefined" && typeof self === "undefined") //server-side rendering should not be making API calls
            return reject({
                status: 777,
                statusText: "Server-side API Call Rejected"
            });
        fetch(url, {
            method,
            body: data,
            headers: bodyType ? new Headers({
                'Content-Type': bodyType,
                'Accept': 'application/json'
            }) : new Headers({
                'Accept': 'application/json'
            }),
            credentials: "include",
            cache: (cached ? "force-cache" : "no-cache")
        }).then(response => {
            if (response.ok)
                response.json().then(resolve, () => reject({
                    status: 700,
                    statusText: "Response returned invalid JSON",
                    url
                } as ApiError));
            else
                reject({
                    status: response.status,
                    statusText: response.statusText,
                    url
                } as ApiError)
        }).catch(reject)
    });
}

export interface ApiError {
    status: number;
    statusText: string;
    url?: string;
}

export function HandleApiError<T>(dispatch: (action: T) => void, displayError?: string, errorAction?: T, operation?: string) {
    return (err: ApiError) => {
        err = err || {
            status: -1,
            statusText: "No Error Delivered"
        };
        console.error(err);
        function reportErr(message?: string, defaultType = "ERROR") {
            return dispatch({ ...((errorAction as any as object) || { type: defaultType }), displayMessage: message || displayError || (errorAction ? undefined : "An error has occurred."), error: err } as any as T);
        }
        switch (err.status) {
            case 777: //server side call
                return;
            case 401:
                return dispatch({ type: "AUTHENTICATION_ERROR" } as any as T);
            case 502:
            case 503:
            case 504:
                if (operation)
                    return reportErr(`${operation} will continue while you use the site.`, "WARNING");
        }
        return reportErr();
    };
}