import { ApplicationState } from '../store';
import $ from 'jquery';
import * as React from 'react';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import * as ModalStore from '../store/Modal';
import './Modal.scss';

type ModalProps = {
    className?: string;
    title?: string;
    onClose?: () => void;
    centered?: boolean;
    buttons?: React.ReactNode[];
};
type ModalInternalProps = ModalProps & ModalStore.ModalState & typeof ModalStore.actionCreators;

let modalOrdinal = 1;
function getOrdinal() {
    return modalOrdinal++;
}

class Modal extends React.Component<ModalInternalProps, { ordinal: number }> {
    state = { ordinal: getOrdinal() };
    componentDidMount() {
        this.props.modalAppear(this.state.ordinal);
    }
    componentWillUnmount() {
        this.props.modalDisappear(this.state.ordinal);
    }
    render() {
        return <Portal>
            <div className={`modal fade show ${this.props.className || ""}`} tabIndex={-1} role="dialog">
                <div className={"modal-dialog " + (this.props.centered ? "modal-dialog-centered" : "")} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            {this.props.title && <h5 className="modal-title">{this.props.title}</h5>}
                            {this.props.onClose && <button type="button" className="close" aria-label="Close" onClick={e => this.props.onClose && this.props.onClose()}>
                                <span aria-hidden="true">×</span>
                            </button>}
                        </div>
                        <div className="modal-body">
                            {this.props.children}
                        </div>
                        {this.props.buttons && <div className="modal-footer">
                            <div className="modal-buttons">
                                {this.props.buttons}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </Portal>;
    }
}

export default connect((state: ApplicationState) => state.modal, ModalStore.actionCreators)(Modal as any) as any as React.ComponentClass<ModalProps>;

class GlobalModalBackground extends React.Component<ModalInternalProps> {
    render() {
        if (this.props.showBackground)
            return <div className="modal-backdrop fade show" />;
        return null;
    }
    componentDidUpdate() {
        if (this.props.showBackground && !$(document.body).is('.no-scroll')) {
            $(document.body).css('opacity', 1); //force DOM reflow
            const bodyWidth = $(document.body).outerWidth() || 0; //sample body width
            $(document.body).addClass("no-scroll").css('opacity', 1); //remove scroll bar, force DOM reflow
            bodyWidth && $(document.body).css('margin-right', ($(document.body).outerWidth() || 0) - bodyWidth); //re-add space formerly taken up by scroll bar
        }
        else if (this.props.showBackground === false)
            $(document.body).removeClass("no-scroll").removeAttr('style'); //re-add scroll bar, remove space filler
    }
}

export const GlobalModalBackdrop = connect((state: ApplicationState) => state.modal, ModalStore.actionCreators)(GlobalModalBackground as any) as any as React.ComponentClass;