import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Alert } from 'reactstrap';
import { actionCreators, AuthorityAssetState } from "../store/AuthorityAsset";
import { ApplicationState } from "../store/index";
import AssetDisplay from './AssetDisplay';
import Loading from "./Loading";

type AssetLookupProps =
    {
        assets: AuthorityAssetState;
    } & typeof actionCreators &
    RouteComponentProps<{ certificateNumber: string }>;

class AssetLookupPage extends React.Component<AssetLookupProps> {

    private get loadedAsset() {
        return this.props.assets && this.props.assets.assetCache && this.props.assets.assetCache[this.props.match.params.certificateNumber];
    }
    private get loading() {
        return this.props.assets ? this.props.assets.loading : false;
    }

    private init() {
        if (typeof this.loadedAsset === "undefined" && !this.loading)
            this.props.loadAssetBySerialNumber(this.props.match.params.certificateNumber);
    }

    componentDidMount() {
        this.init();
    }
    componentDidUpdate() {
        this.init();
    }

    render() {
        return <div className="asset-lookup-page mt-4 px-3">
            {this.loading && <Loading loading />}
            {!this.loading && !this.loadedAsset && <Alert color="primary">Invalid Certificate Number</Alert>}
            {!this.loading && !!this.loadedAsset && <AssetDisplay asset={this.loadedAsset} />}
        </div>;
    }
}

export default connect((state: ApplicationState) => ({ assets: state.authorityAsset }), actionCreators)(AssetLookupPage as any);