import { AppThunkAction } from ".";
import { Reducer, Action } from "redux";

interface ShowModalAction { type: "MODAL-HIDE", ordinal: number };
interface HideModalAction { type: "MODAL-SHOW", ordinal: number };

type KnownAction = ShowModalAction | HideModalAction;

export interface ModalState {
    showBackground: boolean;
    openModals: { [key: number]: boolean };
}

export const actionCreators = {
    modalAppear: (ordinal: number): AppThunkAction<KnownAction> => dispatch => dispatch({ type: "MODAL-SHOW", ordinal }),
    modalDisappear: (ordinal: number): AppThunkAction<KnownAction> => dispatch => dispatch({ type: "MODAL-HIDE", ordinal }),
};

const DefaultState = (): ModalState => ({ showBackground: false, openModals: {} });

export const reducer: Reducer<ModalState> = (state: ModalState | undefined, incomingAction: Action): ModalState => {
    state = state || DefaultState();
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "MODAL-HIDE": {
            const openModals = { ...state.openModals };
            delete openModals[action.ordinal];
            const showBackground = Object.keys(openModals).length > 0;
            return { showBackground, openModals };
        }
        case "MODAL-SHOW": {
            const openModals = { ...state.openModals };
            openModals[action.ordinal] = true;
            return { showBackground: true, openModals };
        }
        default:
            const exhaustiveCheck: never = action;
    }
    return state;
};