import * as React from 'react';
import { AuthorityAsset } from "../store/AuthorityAsset";
import { ReactComponent as CheckIcon } from '../img/icons/check.svg';

import "./AssetDisplay.scss";

type AssetDisplayProps = { asset: AuthorityAsset }

export default class AssetDisplay extends React.Component<AssetDisplayProps> {
    render() {
        const asset = this.props.asset;
        if (!asset)
            return null;

        const tags = asset.additionalTags || {} as AuthorityAsset["additionalTags"];

        return <div className="asset-display">
            <div>
                <div className="row certificate-header text-header">
                    <div className="col-auto pr-0">
                        <CheckIcon className="check-icon" />
                    </div>
                    <div className="col pl-0">
                        <div className="title">Certificate Number</div>
                        <strong>{asset.serialNumber}</strong>
                    </div>
                </div>
            </div>
            <h1 className="asset-title">{asset.title}</h1>
            <div className="asset-body row mx-md-0">
                <div className="asset-images-container col-12 col-md-5 order-md-9 row flex-md-column mx-0 px-0">
                    <div className="asset-images">
                        <div className="card">
                            <img className="card-img-top" src={asset.frontImage} alt="Front" />
                            {/*<div className="card-footer text-header">Front</div>*/}
                        </div>
                        <div className="card">
                            <img className="card-img-top" src={asset.backImage} alt="Back" />
                            {/*<div className="card-footer text-header">Back</div>*/}
                        </div>
                    </div>
                </div>
                <div className="asset-details col order-md-1 px-lg-0">
                    <div className="product-details">
                        {this.pdSharedLine("Certificate Number", asset.serialNumber, "Collectible Type", asset.collectibleType)}
                        {this.pdSharedLine("Year", tags["Year"], "Manufacturer", tags["Manufacturer"])}
                        {Object.keys(tags).filter(t => t != "Year" && t != "Manufacturer").map(t => this.pdLineItem(t, tags[t]))}
                    </div>
                    {asset.productHistory && <React.Fragment>
                        <h3 className="text-header title product-history-title">Product History</h3>
                        <ul className="product-history">
                            {asset.productHistory.map(h => <li key={h.date + ": " + h.description} className="row">
                                <strong className="col-12 col-lg-3 pl-0">{h.date}</strong>
                                <div className="col-12 col-lg-9 pl-0 pl-lg-1">{h.description}</div>
                            </li>)}
                        </ul>
                    </React.Fragment>}
                </div>
            </div>
        </div>;
    }

    private pdSharedLine(title1: string, value1: string, title2: string, value2: string) {
        if (!value1)
            return this.pdLineItem(title2, value2);
        if (!value2)
            return this.pdLineItem(title1, value1);
        return <div className="row" key={title1 + " " + title2}>
            <div className="col-6 col-lg-3 text-header title order-1">{title1}</div>
            <div className="col-6 col-lg-9 pb-2 order-2 order-lg-1">{value1}</div>
            <div className="col-6 col-lg-3 text-header title order-1 order-lg-2">{title2}</div>
            <div className="col-6 col-lg-9 pb-2 order-2">{value2}</div>
        </div>;
    }
    private pdLineItem(title: string, value: string) {
        if (!value)
            return null;
        return <div className="row" key={title}>
            <div className="col-12 col-lg-3 text-header title">{title}</div>
            <div className="col-12 col-lg-9 pb-2">{value}</div>
        </div>;
    }
}