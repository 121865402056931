import * as React from 'react';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import { ReactComponent as Logo } from '../img/icons/authority-logo.svg';
import './NavMenu.scss';

export default class NavMenu extends React.Component<{ showBackground?: boolean }, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header className={`nav ${this.props.showBackground ? "navbar-background" : ""}`} >
                <Route exact path="/">
                    {/* Desktop jump list */}
                    <div>

                    </div>
                </Route>
                <Link to="/"><Logo className="logo" /></Link>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
