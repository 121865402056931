export type QueryStringData = { [key: string]: string | number | boolean | undefined };

export type CollectionExpectedQueryString = {
    filters?: string;
    search?: string;
    mode?: string;
    sort?: string;
}

export default class UrlHelper {

    constructor(private baseUrl: string) { }

    Action(action: string, controller = "", routeValues?: { [key: string]: string }) {
        let queryString = '';
        if (routeValues) {
            queryString = "?" + Object.keys(routeValues).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(routeValues[k])}`).join('&');
        }
        return `${this.baseUrl}${controller ? controller + '/' : ""}${action}${queryString}`;
    }
    Content(url: string) {
        return url.replace(/^~\//, this.baseUrl);
    }
}

export function QueryStringBuilder(qsData: QueryStringData | undefined) {
    return qsData ? `?${Object.keys(qsData).filter(k => typeof qsData[k] !== "undefined").map(k => encodeURIComponent(k) + (typeof qsData[k] === "boolean" && qsData[k] ? "" : `=${encodeURIComponent((qsData[k] || "").toString())}`)).join('&')}` : "";
}

export function QueryStringParser(qs: string = window.location.search) {
    const qsData = {} as QueryStringData;
    if (qs && qs.length > 1) {
        qs.slice(1).split('&')
            .map(kvp => kvp.split('='))
            .filter(kvp => kvp.length > 0)
            .forEach(kvp => qsData[decodeURIComponent(kvp[0])] = kvp.length > 1 ? decodeURIComponent(kvp[1]) : true)
    }
    return qsData;
}

export function CollectionFilterString(qs: string = window.location.search) {
    const qsData = QueryStringParser(qs) as CollectionExpectedQueryString;
    return qsData.filters;
}

export function AddTrailingSlashIfNecessary(url: string) {
    return `${url}${url.endsWith('/') ? "" : "/"}`;
}