import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import Landing from './components/Landing';
import Construction from './components/Construction';
import AssetLookupPage from './components/AssetLookupPage';

import './Site.scss';

export default () => (
    <Layout>
        <Switch>
            {/*<Route path='/Construction' component={Construction} />*/}
            <Route path={`/:certificateNumber`} component={AssetLookupPage} />
            <Route component={Landing} />
        </Switch>
    </Layout>
);
